<template>
  <div class="footer-box">
    <myHeader class="my-header"></myHeader>

    <div class="art-container">


      <div class="art-list">

        <div class="art-item" v-for="(item, index) in list" :key="index" @click="navigateTo">
          <img class="item-p-img item-p-cell" :src="item.productPicture" alt="">
          <div class="item-info">
            <div class="item-p-name item-p-cell">{{ item.productName }}</div>
            <div class="item-price-box">
              <div class="item-p-price item-p-cell">{{ item.placeOfOrigin }}</div>
              <div class="item-p-spec item-p-cell">{{ item.spec + '(' + item.dosageForm + ')' }}</div>
            </div>

            <div class="item-p-pattern item-p-cell">{{ item.barCode }}</div>
          </div>

        </div>
      </div>

      <div class="p-pagin">
        <el-pagination background layout="prev, pager, next" @change="pageChange" :total="totalNum"
          :default-page-size="pageSize" />
      </div>
    </div>
    <myFooter></myFooter>
  </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
  name: 'HomePage',
  components: {
    myFooter,
    myHeader
  },
  data() {
    return {
      list: [],
      totalNum: 0,
      pageSize: 10,
    }
  },
  mounted() {
    let _this = this;
    this.$axios({
      method: 'post',
      url: '/api/product/client/productList',
      data: {
        searchStr: '',
        pageSize: 10,
        pageIndex: 1
      }
    }).then(res => {
      console.log(res);
      _this.list = [...res.data.data, ..._this.list];
      _this.totalNum = res.data.totalNum;
    })
  },
  methods: {
    backTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },

    async getData(params) {
      try {
        let res = await this.$api.productList(params);
        this.list = res.data.data;
        this.totalNum = res.data.totalNum;
        console.log(this.list)
      } catch (err) {
        console.log(err)
      }
    },

    pageChange(e) {
      console.log(e);
      let params = {
        searchStr: '',
        pageSize: this.pageSize,
        pageIndex: e
      }
      this.getData(params);
    }
  }
}
</script>


<style lang="less" scoped>
.art-container {
  margin-top: 140px;
  background-color: #F2F2F2;

  .art-list {
    padding: 20px 5%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .art-item {
      margin: 10px;
      background-color: white;
      width: 280px;
      border-radius: 10px;

      // display: flex;
      // justify-content: space-around;
      .item-p-img {
        width: 280px;
        height: 280px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .item-info {
        padding: 0 10px;

        margin-bottom: 10px;

        .item-price-box {
          display: flex;
          justify-content: space-between;
        }

        .item-p-name {
          text-align: center;
          font-weight: 700;
        }
      }


    }

    .art-item:hover {
      box-shadow: 0px 0px 10px #999;
    }
  }
}

.p-pagin {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

@media (max-width: 768px) {
  .art-list {
    font-size: 10px;
    padding: 10px 10px !important;
    display: flex;

    .art-item {
      //
      justify-content: space-around;
      flex-wrap: nowrap;
      overflow: scroll;

      width: 180px !important;
      margin: 10px 0 !important;

      .item-p-cell {
        line-height: 30px !important;
        font-size: 14px;
      }

      .item-p-name {
        font-size: 18px;
      }

      .item-p-img {
        width: 180px !important;
        height: 180px !important;
      }
    }
  }
}
</style>