<template>
    <div>
        <myHeader class="my-header" @data-meau="handleDataMeau"></myHeader>


        <div class="demo-collapse">
            <div class="page-title">
                联系我们
            </div>

            <div :class="meau ? 'meau-show' : ''">
                <input type="text" v-model="name" class="input" placeholder="名称"><br><br>
                <input v-model="subject" placeholder="主题" class="input" /><br><br>
                <input v-model="phone" placeholder="手机号码" class="input" /><br><br>
                <textarea v-model="message" class="textarea" type="textarea" placeholder="反馈信息"></textarea><br><br>
                <button type="primary" @click="sendTo" class="input send" value="send">提交</button><br><br>
            </div>
        </div>

        <myFooter class="footer"></myFooter>
    </div>

</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';
import { ElMessage } from 'element-plus'

export default {
    data() {
        return {
            name: '',
            subject: '',
            phone: '',
            message: '',

            meau: false,
        }
    },
    components: {
        myFooter,
        myHeader
    },

    mounted() {

    },

    methods: {
        sendTo() {
            if (this.name == '') {
                ElMessage.error('请输入昵称或名字')
                return;
            }
            if (this.subject == '') {
                ElMessage.error('请输入主题')
                return;
            }
            if (this.phone == '') {
                ElMessage.error('请输入手机号')
                return;
            }

            this.$axios({
                method: 'post',
                url: '/api/feedback/client/createFeedback',
                data: {
                    name: this.name,
                    subject: this.subject,
                    phone: this.phone,
                    message: this.message
                }
            }).then(res => {

                if (res.data.code === 0) {
                    ElMessage({
                        message: '添加成功!',
                        type: 'success',
                    })

                }

            })
        },

        handleDataMeau(data) {
            console.log(data);
            // this.meau = data;
        }
    }
}

</script>

<style lang="less" scoped>
.demo-collapse {
    margin-top: 140px;
    padding: 0 30vw;
}

.meau-show {
    display: none;
}

.page-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 100px;
    height: 100px;
    text-align: center;
}

.input {
    width: 100%;
    padding: 0 10px;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #999;
}

.textarea {
    height: 180px;
    line-height: 45px;
    width: 100%;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid #999;
}

.send {
    background-color: black;
    border: black;
    color: white;
    border: none;
}


.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}



@media (max-width: 768px) {
    .page-title {
        font-size: 36px !important;
    }

    .demo-collapse {
        padding: 2vw !important;
    }

    .footer {
        position: relative !important;
        bottom: 0;
        width: 100%;
    }

}
</style>