<template>
    <div class="page">
        <myHeader class="my-header"></myHeader>


        <div class="container">
            <div class="model-1">

                <div class="p-left">
                    <div class="p-left-title">产品分类</div>
                    <div :class="currentCls == index ? 'p-left-item currentCls' : 'p-left-item'"
                        v-for="(item, index) of classify" :key="index" @click="currentChange(index)">
                        <div>{{ item.name }}</div>
                        <div :class="currentCls == index ? 'iconfont icon-right current-icon' : 'iconfont icon-right'">
                        </div>
                    </div>
                </div>

                <div class="p-right">
                    <div class="p-list">
                        <div class="p-item" v-for="(item, index) of list" :key="index">
                            <img class="p-item-img" :src="item.productPicture" alt="vesitin">
                            <h4 class="p-item-title">{{ item.productName }}</h4>
                            <div><span class="p-item-yj">￥{{ item.price }}</span><span class="p-item-xj">￥{{
                                item.currentPrice
                                    }}</span> </div>
                        </div>
                    </div>

                    <div class="p-pagin">
                        <el-pagination background layout="prev, pager, next" @change="pageChange" :total="totalNum"
                            :default-page-size="pageSize" />
                    </div>


                </div>
            </div>

        </div>


        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'HomePage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {
            currentCls: 0,
            totalNum: 0,
            pageSize: 6,
            pageIndex: 1,
            classify: [
                { name: '所有产品' },
                { name: '高端功效型' },
                { name: '母婴莉莉斯' },
                { name: '日销爆款' },
                { name: '香港濟風堂' }
            ],
            list: [],
        }
    },
    async mounted() {
        let params = {
            searchStr: '',
            category: null,
            pageSize: this.pageSize,
            pageIndex: this.pageIndex
        }
        this.getData(params);

    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },

        async getData(params) {
            try {
                let res = await this.$api.productList(params);
                this.list = res.data.data;
                this.totalNum = res.data.totalNum;
                console.log(this.list)
            } catch (err) {
                console.log(err)
            }
        },


        currentChange(index) {
            // console.log(e);
            this.currentCls = index;

            let params = {
                searchStr: '',
                category: this.currentCls,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            this.getData(params);

        },

        pageChange(e) {
            console.log(e);
            let params = {
                searchStr: '',
                category: null,
                pageSize: this.pageSize,
                pageIndex: e
            }
            this.getData(params);
        }
    }
}
</script>

<style lang="less" scoped>
/* 定义动画 */
@keyframes moveright {
    0% {
        transform: translateX(-2);
    }

    50% {
        transform: translateX(8px);
    }

    100% {
        transform: translateX(-2px);
    }
}

.page {
    .container {
        padding-top: 115px;
        width: 100%;
        margin: 0 auto;
        width: 1170px;

        .model-1 {
            padding-top: 50px;
            display: flex;
            justify-content: space-between;

            .p-left {
                width: 269px;

                .p-left-title {
                    font-size: 30px;
                    margin-bottom: 20px;
                }

                .p-left-item {
                    width: 269px;
                    background-color: #1a7567;
                    height: 50px;
                    line-height: 50px;
                    color: white;
                    display: flex;
                    padding: 0 10px;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    border-radius: 10px;

                    .current-icon {
                        animation: moveright 1s infinite;
                    }
                }

                .currentCls {
                    background-color: #07483e;
                    width: 272px;
                    height: 54px;
                    line-height: 54px;
                }
            }

            .p-pagin {
                margin: 10px;
            }

            .p-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 30px 30px 30px;

                // &::after {
                //     content: '';
                //     flex: auto;
                // }

                .p-item {
                    // height: 484px;
                    background: white;
                    margin: 10px;
                    padding: 10px;
                    font-size: 18px;
                    box-shadow: 0px 0px 10px #b0b0b0;
                    border-radius: 5px;

                    .p-item-yj {
                        text-decoration: line-through;
                    }

                    .p-item-xj {
                        font-weight: 700;
                        margin-left: 10px;
                    }

                    .p-item-title {
                        overflow: hidden;
                        height: 28px;
                        width: 232px;
                    }


                    .p-item-img {
                        width: 232px;
                        height: 232px;
                    }

                }
            }
        }
    }
}







@media (max-width: 768px) {
    .banner {
        width: 100vw;
    }

    .container {
        padding-top: 115px;
        width: 100% !important;
        margin: 0 auto;
    }


    .p-list {
        width: 100vw !important;
        padding: 0 0px !important;
        // flex-wrap: wrap;

        .p-item {
            background: white;
            margin: 10px !important;
            padding: 5px !important;
            font-size: 18px;

            // width: 45vw !important;
            // height: auto !important;

            .p-item-yj {
                text-decoration: line-through;
            }

            .p-item-xj {
                font-weight: 700;
                margin-left: 10px;
            }

            .p-item-title {
                overflow: hidden;
                font-size: 14px;
                width: 40vw !important;
                height: auto !important;
            }


            .p-item-img {
                width: 150px !important;
                height: 150px !important;
            }

        }
    }

    .footer {
        height: 138px;
    }


    .model-1{
        display: flex;

        .p-left{
            position: fixed;
            bottom: 10px;
            right: 10px;
            display: none;
        }

        .p-right{
            width: 100vw;
            
        }

    }
}
</style>